<template>
  <s-drawer
    ref="drawer"
    v-model:visible="visible"
    direction="rtl"
    :append-to-body="true"
    size="460px"
    class="product-color__image-drawer"
    @close="onClose"
  >
    <template #title>
      <p class="product-color__title">
        <template v-if="colorTitle">
          {{ colorTitle }} :&nbsp;
        </template>{{ selectedColorText }}
      </p>
    </template>

    <div class="product-color__large-image">
      <div class="scroll-box">
        <ul class="image-list">
          <li
            v-for="(item, index) in list"
            :key="index"
            v-enterkey
            tabindex="0"
            :aria-label="item.attr_value"
            class="image-list-item"
            :class="{ active: item.goods_id === selectedColorId }"
            @click="switchColor(item, index)"
          >
            <!-- <img
              class="color-image"
              :src="transformImg({ img: item['goods_img'] })"
            /> -->
            <CropImageContainer
              :img-src="transformImg({ img: `${item['goods_img']}` })"
              class="color-image"
              ignore-img-ext
              :fixed-ratio="fixedRatio"
              :is-support-crop-image="isSupportCropImage"
            />
            <span class="color-text">{{ item.attr_value }}</span>

            <!-- 售罄蒙版 -->
            <div
              v-if="item.isSoldoutColor"
              class="sold-out-frame"
            ></div>

            <ClientOnly v-if="promotionTagInfos && promotionTagInfos[item.goods_id] && promotionTagInfos[item.goods_id].showVisible">
              <div
                v-if="promotionTagInfos[item.goods_id].tagType === 1 && promotionTagInfos[item.goods_id].unit_discount"
                v-expose="labelExpose(item.goods_id)"
                class="product-color__tag-discount"
              >
                -{{ promotionTagInfos[item.goods_id].unit_discount }}%
              </div>
              <Icon
                v-else-if="promotionTagInfos[item.goods_id].tagType === 2"
                v-expose="labelExpose(item.goods_id)"
                class="product-color__tag-promotion"
                name="sui_icon_activity_16px"
                size="16px"
              />
            </ClientOnly>
            <!-- 环保和热门角标 -->
            <img
              v-else-if="item.isEco && showEco"
              class="short-icon"
              :src="GB_cssRight ? tagIcon.eco.rtl : tagIcon.eco.ltr"
            />
            <img
              v-else-if="item.isNew && isShowAttrNewIcon"
              class="long-icon"
              :src="GB_cssRight ? tagIcon.new.rtl : tagIcon.new.ltr"
            />
            <img
              v-else-if="moreSkcPriceInfosMap && moreSkcPriceInfosMap[item.goods_id] && LowPriceUrl"
              class="short-icon"
              :src="LowPriceUrl"
            />
            <img
              v-else-if="item.isHot"
              class="long-icon"
              :src="GB_cssRight ? tagIcon.hot.rtl : tagIcon.hot.ltr"
            />
            <img
              v-else-if="item.isThickening"
              class="thickening-icon"
              :src="isUs ? tagIcon.thickening.us : GB_cssRight ? tagIcon.thickening.rtl : tagIcon.thickening.ltr"
            />
          </li>
        </ul>
        <ColorPromotionEntry
          :data="promotionTagInfos && promotionTagInfos[selectedColorId]"
          :current-index="currentIndex"
          from="large"
        />
      </div>
    </div>

    <!-- 确认按钮 -->
    <div class="product-color__confirm-box">
      <s-button
        class="confirm-btn"
        :type="['primary']"
        :width="'460px'"
        @click="onConfirm"
      >
        {{ language.SHEIN_KEY_PC_24406 }}
      </s-button>
    </div>
  </s-drawer>
</template>
<script>
import ColorPromotionEntry from './ColorPromotionEntry.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { transformImg } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
import tagIcon from '../../utils/colorTagIcon'

const { GB_cssRight = false, SiteUID } = gbCommonInfo

export default {
  name: 'LargeImageColorDrawer',
  components: {
    ClientOnly,
    ColorPromotionEntry,
    CropImageContainer,
    Icon
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => ([]),
    },
    colorTitle: {
      type: String,
      default: ''
    },
    selectedColorId: {
      type: [String, Number],
      default: '',
    },
    lazyImg: {
      type: String,
      default: ''
    },
    eventCategory: {
      type: String,
      default: ''
    },
    reviewLocation: {
      type: String,
      default: ''
    },
    showEco: {
      type: [Boolean, undefined],
      default: false,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    colorsCompData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    loadConf: {
      type: Object,
      default: () => ({
        size: '750x',
        num: 1
      })
    },
    moreSkcPriceInfosMap: {
      type: Object,
      default: () => ({})
    },
    fixedRatio: {
      type: String,
      default: '3-4'
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    isShowAttrNewIcon: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close', 'switchColor'],
  data () {
    return {
      tagIcon,
      GB_cssRight,
      reported: false
    }
  },
  computed: {
    LowPriceUrl() {
      if (typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined') {
        return gbCommonInfo?.GB_cssRight ?
          'https://img.ltwebstatic.com/images3_ccc/2024/10/21/36/1729497635a16579940238c214568103df4529d7f4.png' :
          'https://img.ltwebstatic.com/images3_ccc/2024/10/21/3a/1729497443040b42f4423d3b4a51c6daab089ec29c.png'
      }
      return ''
    },
    promotionTagInfos() {
      return this.colorsCompData?.promotionTagInfos || {}
    },
    // 选中颜色文本
    selectedColorText() {
      const { selectedColorId, list } = this
      if (!Array.isArray(list) || !list.length || !selectedColorId) {
        return ''
      }
      const selectedColorItem = list.find(item => item.goods_id === selectedColorId)
      return selectedColorItem?.attr_value || ''
    },

    isUs() {
      return SiteUID === 'us'
    }
  },
  watch: {
    visible(val) {
      const { reported, list } = this
      if (val && !reported) {
        let color_list = []
        list.forEach(item => {
          color_list.push({
            color: item.goods_id,
            icon_type: item.icon_type,
          })
        })
        daEventCenter.triggerNotice({
          daId: '1-8-6-24',
          extraData: {
            color_list,
            if_have_switch_image_entry: 0,
            location: 'popup',
            image_tp: 'large',
            promotion_type: this.colorsCompData?.promotionTypeId || '-',
            source: this.loadConf?.source
          },
        })
        this.reported = true
      }
    }
  },
  methods: {
    transformImg,
    onConfirm() {
      const { onClose, selectedColorId } = this
      onClose()
      daEventCenter.triggerNotice({
        daId: '1-8-6-122',
        extraData: {
          color: selectedColorId,
          location: 'popup',
          image_tp: 'large'
        },
      })
    },
    onClose() {
      this.$emit('close')
    },
    switchColor(item, index) {
      // if (item?.isSoldoutColor) { // 新需求 要放开
      //   return
      // }
      this.$emit('switchColor', item, true, index)
    },
    labelExpose(goods_id) {
      const promotionInfo = this.promotionTagInfos?.[goods_id]
      return {
        id: '1-6-1-141',
        once: true,
        data: {
          promotion_type: promotionInfo?.typeId,
          style: this.loadConf?.source,
          skc: promotionInfo?.goods_sn
        }
      }
    },
  },
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.product-color__image-drawer {
  .product-color__title {
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    color: #000;
  }

  .sui-drawer__title {
    padding: 32px 40px;
  }

  .sui-drawer__close-btn {
    cursor: pointer;
  }

  .product-color__confirm-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 460px;
    height: 80px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.product-color__large-image {
  width: 380px;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  padding-top: 8px;
  padding-bottom: 32px;

  .scroll-box {
    width: 420px;
    height: 100%;
    overflow-y: scroll;

    .image-list {
      display: flex;
      flex-wrap: wrap;
      width: 380px;
    }
  }

  .image-list-item {
    box-sizing: border-box;
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-bottom: 16px;
    margin-left: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    .color-image {
      width: 114px;
      display: inline-block;
    }

    .color-text {
      font-size: 12px;
      color: #767676;
      max-width: 114px;
      margin: 6px 0 4px 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
    }

    &.active {
      border: 1px solid #222;

      .color-text {
        color: #222;
      }
    }

    .sold-out-frame {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.6);
      cursor: not-allowed;
    }
    .long-icon {
      position: absolute;
      top: -2px;
      right: -2px;
      width: 34px;
      height: 14px;
      border-radius: 0;
      z-index: 1;
    }
    .short-icon {
      position: absolute;
      top: -2px;
      right: -2px;
      width: 22px;
      height: 14px;
      border-radius: 0;
      z-index: 1;
    }
    .thickening-icon {
      position: absolute;
      top: -2px;
      right: -2px;
      width: 26px;
      height: 14px;
      border-radius: 0;
      z-index: 1;
    }
  }
}

.product-color__tag-discount {
      position: absolute;
      top: 0;
      right: 0;
      transform-origin: 100% 0;
      transform: scale(.625);

      border: 1.28px solid rgba(255, 255, 255, 0.86);
      border-radius: 3.2px;
      background: @sui_color_promo_bg;
      padding: 0 3.2px;
      overflow: hidden;

      font-size: 16px;
      line-height: 19.2px;
      color: @sui_color_promo_dark;
      white-space: nowrap;
}
.product-color__tag-promotion {
      position: absolute;
      top: 0;
      right: 0;
      transform-origin: 100% 0;
      transform: scale(.625);

      border: 1.28px solid rgba(255, 255, 255, 0.86);
      border-radius: 3.2px;
      background: @sui_color_promo_bg;
      padding: 0 3.2px;
      overflow: hidden;

      font-size: 16px;
      line-height: 19.2px;
      color: @sui_color_promo_dark;
      white-space: nowrap;
}
</style>
