<template>
  <div
    v-if="goodsData && goodsData.detail"
    class="goods-btn__container"
  >
    <!-- 积分收集提示 -->
    <div
      v-if="!isSoldOut && mallStock != 0"
      class="goods-btn__collect"
    >
      <template v-if="showRWIntegralTips">
        <p v-html="integralTips(currnetPoint)"></p>
      </template>
      <p v-else-if="!IS_RW && !quickViewConfig.birthdayGift">
        <!-- TODO del doublePoints > 0-->
        <template v-if="goodsDetail.doublePoints > 0">
          {{ language.SHEIN_KEY_PC_14616 }}&nbsp;
          <span>
            {{
              currnetPoint * addBagForm.quantity +
                goodsDetail.doublePoints * addBagForm.quantity
            }}
            <span
              style="color: #222"
            >({{ currnetPoint * addBagForm.quantity }} +</span>
            <span>{{ goodsDetail.doublePoints * addBagForm.quantity }}</span>
            <span style="color: #222">)</span>
          </span>
          &nbsp;{{ language.SHEIN_KEY_PC_14603 }}
        </template>
        <template v-else-if="currnetPoint * addBagForm.quantity > 0">
          <p
            v-html="
              integralTips(currnetPoint * addBagForm.quantity)
            "
          ></p>
        </template>
      </p>
    </div>

    <!-- 商品售罄的提示 -->
    <p
      v-if="
        (mallStock == 0 || (isSoldOut && status.showSimilarBtn)) && !unListed && !(findSimilarFloatInfo && findSimilarFloatInfo.show) && !exchangeInfo.isExchangeButton
      "
      :class="[
        'soldout-warning',
        !isSoldOut && mallStock == 0 ? 'mall-tip' : '',
      ]"
    >
      {{ soldOutTips }}
      <a
        v-if="isSoldOut && !quickViewConfig.styleType"
        href="javascript:;"
        @click="handleShowSimilarModal"
      >{{ language.SHEIN_KEY_PC_16963 }}</a>
    </p>

    <!-- 无权领取生日礼提示语 -->
    <p
      v-else-if="
        quickViewConfig.birthdayGift &&
          !quickViewConfig.birthdayGift.hasAuthority4Gifts
      "
      class="goods-btn__vip-birth-unable"
    >
      {{ language.SHEIN_KEY_PC_17450 }}
    </p>

    <!-- 商品按钮 -->
    <div>
      <div class="goods-btn__err">
        <!-- 限时折扣没有限制数量字段 -->
        <span v-if="status.purchaseLimit">
          {{
            language.SHEIN_KEY_PC_14661
          }}
        </span>
        <span v-if="status.resultSoldout && !exchangeInfo.isExchangeButton">
          {{
            language.SHEIN_KEY_PC_14615
          }}
        </span>
        <!-- 清仓限购 -->
        <span v-if="status.clearance">
          {{
            template(goodsData.detail.limit_count, language.SHEIN_KEY_PC_14595)
          }}
        </span>
        <span v-if="status.clearanceLimit">
          {{
            template(
              goodsData.detail.limit_count,
              htmlDecode({ text: language.SHEIN_KEY_PC_14560 })
            )
          }}
        </span>
        <span v-if="status.flashSaleLimit">
          {{
            template(
              err.limitCount,
              htmlDecode({ text: err.tip || language.SHEIN_KEY_PC_14560 })
            )
          }}
        </span>
        <span v-if="exchangeInfo.showDisabledExchange">
          {{ exchangeInfo.disabledExchangeText }}
        </span>
      </div>
      <div class="goods-btn__group">
        <!-- 售罄 -->

        <!-- style-type-1 find similar -->
        <button
          v-if="(isSoldOut && !unListed && quickViewConfig.styleType && !(findSimilarFloatInfo && findSimilarFloatInfo.show)) || ''"
          class="goods-btn__add goods-btn__horizontal find-similar"
          :style="customSubmitBtnStyle"
          @click="handleShowSimilarModal"
        >
          {{ language.SHEIN_KEY_PC_16963 }}
        </button>
        <button
          v-else-if="(isSoldOut || mallStock == 0) && !exchangeInfo.isExchangeButton"
          class="goods-btn__add goods-btn__add-soldout goods-btn__horizontal"
          disabled
        >
          <template v-if="exchangeInfo.isExchangeButton">
            {{ language.SHEIN_KEY_PC_16719 }}
          </template>
          <template v-else>
            {{
              language['QvSoldOut'] ||
                (unListed
                  ? language.SHEIN_KEY_PC_17424
                  : mallStock == 0 && !isSoldOut
                    ? language.SHEIN_KEY_PC_14634
                    : language.SHEIN_KEY_PC_14617)
            }}
          </template>
        </button>
        <button
          v-else-if="isBuyNow"
          class="goods-btn__add"
          @click="clickBuyNow"
        >
          <p v-show="!status.addLoading">
            {{ language.SHEIN_KEY_PC_30206 || 'BUY NOW' }}
          </p>
          <div
            v-show="status.addLoading"
            class="goods-btn__add goods-btn__add-loading"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <!-- 加车按钮 -->
        <div
          v-else
          class="button-box"
          :class="{
            'OCP-type': showOneClickPayBtn && !onlyOneClickPayBtn,
          }"
        >
          <!-- 一键购按钮 -->
          <OneClickPayBtn
            :show="showOneClickPayBtn"
            :scene="'QuickView'"
            :bill-info="oneClickPayState.billInfo"
            :goods-data="goodsData.detail"
            :quantity="addBagForm.quantity"
            :extra-pay-params="extraOneClickPayParams"
            :before-pay="testBeforeOCP"
            :language="language"
            :analysis-data="oneClickPayAnalysisData"
            @pay-success="onOneClickPaySuccess"
            @pay-fail="onOneClickPayFail"
            @trans-complete="onOneClickPayTransComplete"
          />

          <template v-if="!onlyOneClickPayBtn">
            <!-- loading -->
            <button
              v-if="status.addLoading"
              class="goods-btn__add goods-btn__add-loading goods-btn__horizontal"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <!-- loading 结束 -->
            <button
              v-else
              class="goods-btn__add"
              :class="{
                'goods-btn__horizontal': showSaveBtn,
              }"
              :disabled="!!exchangeInfo.showDisabledExchange"
              :style="{
                ...(isIpad ? { transition: 'background 0s, border-color,0s, color 0s' } : {}),
                ...customSubmitBtnStyle
              }"
              @click="handleClickAddToBag"
            >
              <!-- 显示请求结果 -->
              <template v-if="status.showDone">
                <template v-if="status.doneSuccess">
                  {{ language.SHEIN_KEY_PC_14604 }}
                </template>
                <template v-else>
                  {{ language.SHEIN_KEY_PC_15235 }}
                </template>
              </template>
              <!-- updata -->
              <span v-else-if="quickViewConfig.isUpdateButton">
                {{
                  language.SHEIN_KEY_PC_14549
                }}
              </span>
              <!-- exchange -->
              <span
                v-else-if="exchangeInfo.isExchangeButton" 
              >
                {{ language.SHEIN_KEY_PC_14549 }}
              </span>
              <!-- next -->
              <span v-else-if="quickViewConfig.isNextButton">
                {{
                  language.SHEIN_KEY_PC_17448
                }}
              </span>
              <!-- cutomize -->
              <span v-else-if="isCustomizeMain">
                {{
                  language.SHEIN_KEY_PC_27604
                }}
              </span>
              <!-- 套装价更新销售属性 -->
              <span v-else-if="quickViewConfig.isShowSelectUpdate">
                {{
                  language.SHEIN_KEY_PC_14549
                }}
              </span>
              <!-- add -->
              <span v-else>
                {{
                  language['QvAddToBag'] || language.SHEIN_KEY_PC_14634
                }}
              </span>

              <!-- <BtnContentWithPrice
                :default-btn-text="contentText"
                :language="language"
                :countdown-tips="countdownTips"
                :is-show-countdown="isShowCountdown"
                :best-deal-config="bestDealConfig"
                :soldout-state="soldoutState"
                :is-showflash-countdown="priceBannerTypeNormalFlashData.showCountTime"
                :flash-countdown-tips="flashCountdownTips"
                :ccc-add-cart-prompt="cccAddCartPrompt"
                :discount-percent="discountPercent"
              />  -->
            </button>
          </template>
        </div>

        <!-- 收藏按钮 -->
        <div
          v-if="showSaveBtn"
          class="goods-btn__like goods-btn__horizontal"
          :aria-label="language.SHEIN_KEY_PC_17382"
          @click.stop="handleClickAddWish"
        >
          <template v-if="wishlistData[goodsData.detail.goods_id]">
            <Icon
              name="sui_icon_save_completed_32px"
              class="goods-btn__like-icon main-color anim"
              size="32"
              :color="IS_RW ? '#FC4070' : '#222222'"
            />
          </template>
          <template v-else>
            <Icon
              class="goods-btn__like-icon"
              name="sui_icon_save_32px"
              size="32"
            />
          </template>
          <div
            v-if="status.isShowAddGroup"
            class="goods-btn__like-group"
            @click.stop="handleClickToCroup"
          >
            <Icon
              name="sui_icon_addtoboard_28px_1"
              size="28"
            />
            <span>{{ language.SHEIN_KEY_PC_17581 }}</span>
          </div>
        </div>
      </div>
    </div>


    <!-- view full detail -->
    <!-- <a
      v-if="!quickViewConfig.hideViewFullDetail && IS_RW"
      class="view-detail"
      @click.stop="clickViewFullDetail"
    >
      {{ language.SHEIN_KEY_PC_14608 }}
      {{ quickViewConfig.styleType ? '>' : '' }}
    </a>
    <a
      v-if="!quickViewConfig.hideViewFullDetail && !IS_RW"
      class="view-detail-new"
      @click.stop="clickViewFullDetail"
    >
      {{ language.SHEIN_KEY_PC_14608 }}
      <span class="view-detail-new__more">
        <Icon
          name="sui_icon_more_right_18px"
          size="12px"
          class="view-detail-new__more-icon"
        />
      </span>
    </a> -->
  </div>
</template>

<script>
import {
  getQueryString,
  CountDown,
  debounce,
  isMobile,
  template,
  htmlDecode,
  minus
} from '@shein/common-function'
// global variable: SHEIN_W gbExposeTraceid  cart_module  GB_ga_transformPrice
const { lang, WEB_CLIENT, IS_RW, SiteUID } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter'
import SimilarPopover from 'public/src/pages/common/similar_products'
import OneClickPayBtn from 'public/src/pages/goods_detail_v2/components/OneClickPay/OneClickPayBtn'
import emarsys from 'public/src/services/productRecommend/emarsys'
import { groupDialog } from 'public/src/pages/components/wishlist/index'
import { SMessage } from '@shein-aidc/sui-message'
import { abtservice } from 'public/src/services/abt'
import schttp from 'public/src/services/schttp'
import { addWishGoodsApi } from 'public/src/services/api/user/wishlist.js'
import { SToast } from '@shein-aidc/sui-toast'
import mitt from 'mitt'
import { getGoodsUrl, setDetailCacheCookie } from 'public/src/pages/common/utils/index.js'
import { autoGetCouponAtAddBagNotMain } from 'public/src/pages/goods_detail_v2/utils/productDetail'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
import { buyNow, triggerSubmitBuyNow } from 'public/src/pages/goods_detail_v2/utils/buyNow.js'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { monitor } from '../quick_add_monitor'

daEventCenter.addSubscriber({ modulecode: '1-6-1' }) // 相似商品
daEventCenter.addSubscriber({ modulecode: '1-7-1' }) // 购物车
daEventCenter.addSubscriber({ modulecode: '1-4-1' }) // 收藏
daEventCenter.addSubscriber({ modulecode: '2-8' })

let customerMade
if (typeof window !== 'undefined') {
  import('public/src/pages/goods_detail_v2/components/CustomeowDrawer/index.js').then(res => {
    customerMade = res.default
  })
}
let timer = null

export default {
  name: 'GoodsButton',
  components: {
    // BtnContentWithPrice,
    OneClickPayBtn,
    Icon
  },
  props: {
    activeId: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    initLoginStatus: {
      type: Boolean,
      default: true,
    },
    popupModal: {
      type: Boolean,
      default: true,
    },
    mainId: {
      type: String,
      default: ''
    },
    activePromotionId: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    goodsData: {
      type: Object,
      required: true
    },
    promotionInfo: {
      type: Array,
      default: () => ([])
    },
    // quickView 加车form
    addBagForm: {
      type: Object,
      required: true,
      default() {
        return {
          sizeInfo: null
        }
      }
    },
    analysis: {
      type: Object,
      default() {
        return {}
      }
    },
    wishlistData: {
      type: Object,
      default() {
        return {}
      }
    },
    quickViewConfig: {
      type: Object,
      default() {
        return {}
      }
    },
    quickViewCb: {
      type: Object,
      default() {
        return {}
      }
    },
    saleAttrList: {
      type: Object,
      default() {
        return {}
      }
    },
    defaultMallCode: {
      type: [String, Number],
      default: ''
    },
    commonAbtParams: {
      type: Object,
      default() {
        return {}
      }
    },
    // 展示一键购按钮
    showOneClickPay: {
      type: Boolean,
      default: false
    },
    // 一键购状态
    oneClickPayState: {
      type: Object,
      default() {
        return {}
      }
    },
    goodsPicType: {
      type: [String, null],
      default: null
    },
    fixedRatio: {
      type: String,
      default: '3-4'
    },
    findSimilarFloatInfo: {
      type: Object,
      default: () => ({})
    },
    pageListType: {
      type: String,
    },
    estimatedInfo: {
      type: Object,
      default: () => ({})
    },
    mainGoodsId: {
      type: String,
      default: ''
    },
    showBuyNowReason: {
      type: Object,
      default: () => ({
        reason: '',
        isShow: false
      })
    },
    bestDealConfig: {
      type: Object,
      default: () => ({})
    },
    cccAddCartPrompt: {
      type: Array,
      default: () => ([]),
    },
    monitorData: {
      type: Object,
      default: () => ({})
    }
  },
  inject: ['changeRootStatus', 'hide', 'changeRootData', 'changeAddBagData'],
  data() {
    return {
      priceBannerTypeNormalFlashData: {
        showCountTime: false,
        flashTimer: null,
        secondInterval: null,
        countdownStr: {
          D: '',
          H: '',
          M: '',
          S: ''
        },
        secondHundred: 9,
      },
      EstimatedTagProps: {},
      countdownObj: null,
      flashTimer: null,
      countTimer: {},
      isShowCountdown: false,
      IS_RW,
      err: {
        limitCount: 1,
        tip: ''
      },
      status: {
        clearance: false,
        clearanceLimit: false,
        flashSaleLimit: false,
        showSimilarBtn: false,
        addLoading: false,
        // 收藏loading
        addWishLoaidng: false,
        resultSoldout: false,
        purchaseLimit: false,

        isHasWishGroup: false, // 是否有收藏分组了
        isShowAddGroup: false, // 是否展示加入收藏分组

        // 下面状态有依赖关系
        showDone: false,
        doneSuccess: true
      },
      isIpad: isMobile(), // ipad点击加车按钮偶尔失效的bug
      // similarBtnAbt: null
      pointMap: {
        sku: {},
        skc: {}
      }
    }
  },
  computed: {
    mallStock() {
      return this.goodsData.mallStock
    },
    mallName() {
      return this.goodsData.detail.mall?.[this.mallCode]?.mall_name
    },
    skuList() {
      return this.saleAttrList?.sku_list || []
    },
    hasAttrs() {
      return this.skuList.length
    },
    mallCode() {
      return this.goodsData.mallCode
    },
    quickShip() {
      return this.goodsData.quickShip
    },
    soldOutTips() {
      const {
        isSoldOut,
        mallStock,
        mallName,
        language,
        quickViewConfig
      } = this
      return !isSoldOut && mallStock == 0
        ? template(mallName, language.SHEIN_KEY_PC_21952)
        : quickViewConfig.styleType
          ? language.SHEIN_KEY_PC_21942
          : language.SHEIN_KEY_PC_16967
    },
    /**
     * 是否是售罄的标识
     */
    isSoldOut() {
      if (!this.goodsData.detail) return false
      // return true
      // 1. 当前商品已经下架
      if (this.goodsData.detail.is_on_sale == 0) return true
      // 2. 全尺码缺货
      if (this.hasAttrs && this.skuList.every(size => size.stock <= 0))
        return true

      // 3. 根据选择的销售属性库存判断
      if (this.hasAttrs && this.addBagForm.sizeInfo) {
        // 当前尺码缺货吗？
        const nowSizeSoldOut =
          this.addBagForm.sizeInfo &&
          Number(this.addBagForm.sizeInfo.stock) <= 0
        return nowSizeSoldOut
      } else {
        // 4. 当前商品的库存
        return Number(this.goodsData.detail.stock) <= 0
      }
    },
    /**
     * 跳转到详情页的完整链接
     */
    detailFullLink() {
      if (this.goodsData.detail) {
        const { goods_url_name, goods_id, cat_id } = this.goodsData.detail
        const { detailUrlExtendParam } = this.quickViewConfig
        let extendUrl = ''
        if (detailUrlExtendParam) {
          Object.keys(detailUrlExtendParam).forEach((item) => {
            extendUrl += `&${item}=${detailUrlExtendParam[item]}`
          })
        }
        if (this.oneClickPayState?.upperBillNo) {
          extendUrl += `&billno=${this.oneClickPayState?.upperBillNo}`
        }
        if (this.pageListType !== undefined) {
          extendUrl += `&pageListType=${this.pageListType || ''}`
        }
        if(this.quickViewConfig.add_source){
          // 新增加车归因参数add_source
          extendUrl += `&add_source=${this.quickViewConfig.add_source}`
        }
        return (
          getGoodsUrl(goods_url_name, goods_id, cat_id) +
          '?mallCode=' +
          this.mallCode +
          '&imgRatio=' +
          this.fixedRatio + 
          extendUrl
        )
      }
      return ''
    },
    /**
     * 预售商品
     */
    unListed() {
      return (
        this.goodsData.gradeState[0] &&
        this.goodsData.gradeState[0].new_product_unsale == 1
      )
    },
    /**
     * 判断是否是闪购商品
     */
    isFlashGoods() {
      return (
        this.promotionInfo?.some(
          item => Number(item.typeId) === 10
        )
      )
    },
    flashGoods() {
      return this.promotionInfo &&
        this.promotionInfo?.find?.(item => item.typeId == 10)
    },
    language() {
      return this.$root.language || {}
    },
    goodsDetail() {
      return (this.goodsData && this.goodsData.detail) || {}
    },
    // 是否显示双倍积分
    showRWIntegralTips() {
      const { rewardPoints } = this.goodsDetail
      return IS_RW && lang !== 'mx' && rewardPoints > 0
    },
    skuInfo() {
      const { addBagForm } = this
      return addBagForm?.sizeInfo
    },
    // 是否支持一键购
    supportOneClickPay() {
      const { showOneClickPay, isSoldOut, goodsData, oneClickPayState } = this
      return (
        showOneClickPay &&
        !isSoldOut &&
        goodsData.oneClickPayAuth &&
        oneClickPayState.support
      )
    },
    // 是否展示一键购按钮
    showOneClickPayBtn() {
      const {
        supportOneClickPay,
        oneClickPayState,
        skuInfo,
        goodsData,
        mallCode,
        isCustomizatie
      } = this
      const { isShowSelectUpdate } = this.quickViewConfig
      // skc 层面的支持
      if (!supportOneClickPay || isCustomizatie || isShowSelectUpdate) return false

      // 若存在 sku 且进行了选择，则需要比对 sku 的价格以及该 sku 在一键购信息中的库存
      const { sku_code = '', mall } = skuInfo || {}
      const { billInfo } = oneClickPayState || {}
      const {
        can_add_purchase_price: limitPrice,
        skc_inventory_list: inventoryList,
      } = billInfo || {}
      // 一键购权限信息未精确到 sku 层面，则直接展示
      if (!limitPrice || !Array.isArray(inventoryList)) {
        return true
      }

      const limitPriceNum = Number(limitPrice) || 0
      // 未选择尺码，只检测价格
      if (!sku_code) {
        const currentMall = goodsData?.detail?.mall[mallCode]
        const skcPrice = currentMall?.salePrice?.usdAmount
        const skcPriceNum = Number(skcPrice) || 0
        if (skcPriceNum && (skcPriceNum > limitPriceNum)) {
          return false
        }
        return true
      }

      const { inventory_list } = inventoryList[0] || {}
      if (!Array.isArray(inventory_list)) return true
      // 取出匹配当前 sku 的一键购单元，没有匹配认为不支持
      const currentSku = inventory_list.find(
        (item) => item.sku === sku_code
      )
      if (!currentSku) return false
      const { is_have_inventory = 0 } = currentSku
      // 库存检测
      if (!is_have_inventory) {
        return false
      }
      // 价格检测
      const currentSkuPrice = mall?.[mallCode]?.salePrice?.usdAmount
      const currentSkuPriceNum = Number(currentSkuPrice) || 0
      if (currentSkuPriceNum && (currentSkuPriceNum > limitPriceNum)) {
        return false
      }

      return true
    },
    // 仅展示一键购按钮
    onlyOneClickPayBtn() {
      const { showOneClickPayBtn, oneClickPayState } = this
      if (!showOneClickPayBtn) {
        return false
      }
      return !!oneClickPayState.upperBillNo
    },
    // 一键购下单请求额外参数
    extraOneClickPayParams() {
      const { goodsData, mallCode, addBagForm } = this
      const currentMall = goodsData.detail.mall[mallCode]
      const add_source = this.quickViewConfig.add_source || this.quickViewConfig.addSource || ''
      return {
        sku_code: addBagForm.sizeInfo?.sku_code || '',
        mall_code: mallCode,
        amount: currentMall?.salePrice?.amount || '',
        add_source: add_source ? `detail_${add_source}` : '',
      }
    },
    // 一键购埋点数据
    oneClickPayAnalysisData() {
      const { oneClickPayState = {} } = this
      const { upperBillNo } = oneClickPayState
      return {
        location: 'popup',
        order_no: upperBillNo || '',
        activity_from: upperBillNo ? 'one_tap_pay' : ''
      }
    },
    isAb () {
      return this.estimatedInfo?.isAb
    },
    isCustomizatie(){
      const customizationFlag = this.goodsData?.detail?.customization_flag
      return customizationFlag == 1
    },
    isMainProduct(){
      const popupGoodsId = this.goodsData?.detail?.goods_id
      return popupGoodsId == this.mainId
    },
    isCustomizeMain(){
      return this.isCustomizatie && this.isMainProduct
    },
    isCustomizeNotMain(){
      return this.isCustomizatie && !this.isMainProduct
    },
    exchangeInfo() {
      let { exchangeOrderPrice, isExchangeButton, exchangeOrderSkuCode } = this.quickViewConfig
      let { SHEIN_KEY_PC_29222, SHEIN_KEY_PC_29223, SHEIN_KEY_PC_29224, SHEIN_KEY_PC_29225 } = this.language
      
      let disabledExchangeText = ''
      
      if (isExchangeButton) {
        // 这是您订单的商品，可选择其他商品进行换货 SHEIN_KEY_PC_29223 1 skuCode
        if (exchangeOrderSkuCode == this.skuInfo?.sku_code) {
          disabledExchangeText = SHEIN_KEY_PC_29223
        }
        // 商品无法换货，因为商品已售罄 SHEIN_KEY_PC_29224 2
        if (this.isSoldOut || this.mallStock == 0) {
          disabledExchangeText = SHEIN_KEY_PC_29224
        }
        // 商品无法换货，因为是定制商品 SHEIN_KEY_PC_29225 3 isCustomization
        if (this.isCustomizatie) {
          disabledExchangeText = SHEIN_KEY_PC_29225
        }
        // 商品无法换货，因为价格不符合条件 SHEIN_KEY_PC_29222 4
        let isSelectedSku = !!this.skuInfo?.sku_code
        let usDollarOrderPrice = Number(exchangeOrderPrice?.usdAmount)
        if (isSelectedSku) {
          let usDollarPrice = Number(this.skuInfo.price.salePrice?.usdAmount)
          if (Math.abs(minus(usDollarOrderPrice, usDollarPrice)) > 1 ) {
            disabledExchangeText = SHEIN_KEY_PC_29222
          }
        } else {
          // 未选中 sku 只选中 skc
          this.skuList.forEach(item => {
            let usDollarPrice = Number(item.price.salePrice?.usdAmount)
            if (Math.abs(minus(usDollarOrderPrice, usDollarPrice)) > 1 ) {
              disabledExchangeText = SHEIN_KEY_PC_29222
            }
          })
        }
      }

      let showDisabledExchange = isExchangeButton && disabledExchangeText

      return {
        isExchangeButton,
        showDisabledExchange,
        disabledExchangeText
      }
    },
    customSubmitBtnStyle() {
      let { buttoncolor } = this.goodsData?.screenAbtConfig?.Specialcontrol?.p || {}
      if (buttoncolor !== 'Special') return {}
      return this.config?.customSubmitBtnStyle || {}
    },
    showSaveBtn() {
      let { buttonshow } = this.goodsData?.screenAbtConfig?.Specialcontrol?.p || { buttonshow: 'Show' }
      if (buttonshow === 'NotShow') return !this.quickViewConfig.hideWishlistBtn
      return true
    },
    isBuyNow() {
      return this.quickViewConfig.isBuyNow
    },
    isFlashSaleCountDownAbtHide() {
      return this.goodsData.screenAbtConfig?.FlashSaleCountDown?.p?.FlashSaleCountDown === 'Hide'
    },
    flashCountdownTips() {
      const { H, M, S } = this.priceBannerTypeNormalFlashData.countdownStr || {}
      if(!H && !M && !S) return ''
      return H + ':' + M + ':' + S
    },
    countdownTips() {
      const { H, M, S } = this.countTimer?.timeObj || {}
      if(!H && !M && !S) return ''
      return H + ':' + M + ':' + S
    },
    isPaidUser() {
      return this.goodsData?.isPaidUser
    },
    productInfo() {
      return this.goodsData.detail || {}
    },
    productInfoPrice() {
      return this.productInfo?.mall?.[this.mallCode] || {}
    },
    priceSource() {
      let result = this.addBagForm?.sizeInfo?.mall?.[this.mallCode] || this.productInfoPrice
      // 手动屏蔽到手价ab价
      let iscloseEstimatedAndAbPriceWithPromo32 = this.promotionInfo?.find?.(item => item.typeId == 32) && this.goodsData?.closeEstimatedAndAbPrice
      return {
        ...result,
        ...( iscloseEstimatedAndAbPriceWithPromo32 ? { salePrice: result.retailPrice, unit_discount: 0 } : {})
      }
    },
    discountPercent() {
      // 有到手价优先到手价，否则取售价折扣
      const { discountPercentForBelt } = this.estimatedInfo || {}
      if (discountPercentForBelt) return discountPercentForBelt
      const disPriece = this.priceSource.discountValue || this.priceSource.unit_discount
      return disPriece ? disPriece  + '%' : ''
    },
    btnSoldOutTips() {
      if (this.skuInfo?.sku_code) {
        return this.changeTips(this.skuInfo?.mall?.[this.mallCode]?.stock)
      } else {
        return this.language.SHEIN_KEY_PC_16990
      }
    },
    soldOutTipsLimitNum () {
      // 库存提示上限
      const { onlyxleft = {} } = this?.goodsData?.screenAbtConfig || {}
      return onlyxleft?.p?.onlyxleft === 'ten' ? 10 : 5
    },
    // 所有sku库存紧张
    almostSoldOut() {
      let skuStock = 0
      for (let i = 0; i < this.skuList.length; i++) {
        skuStock += +this.skuList[i]?.mall?.[this.mallCode]?.stock || 0
        if (skuStock > this.soldOutTipsLimitNum) {
          return false
        }
      }
      if (skuStock == 0) {
        return false
      }
      return true
    },
    isAllSoldOut() {
      return (
        Number(this.goodsData?.detail?.is_on_sale) === 0 ||
        Number(this.goodsData?.detail?.stock) === 0
      )
    },
    sizeStockTips() {
      let judge
      if (this.skuInfo?.sku_code) {
        const mall = this.skuInfo?.mall?.[this.mallCode]
        judge = mall?.stock > 0 && mall?.stock <= this.soldOutTipsLimitNum
      } else {
        judge = !this.isAllSoldOut && this.almostSoldOut
      }
      // TODO
      return judge 
    },
    soldoutState() {
      return {
        showSoldOutTips: this.sizeStockTips,
        soldOutTips: this.btnSoldOutTips,
      }
    },
    currnetPoint() {
      let skc = this.goodsData?.detail?.goods_id || ''
      let sku = this.skuInfo?.sku_code || ''
      let { pointMap } = this
      if (sku && pointMap.sku[sku]) return pointMap.sku[sku]
      if (skc && pointMap.skc[skc]) return pointMap.skc[skc]
      return 0
    }
  },
  watch: {
    isSoldOut: {
      deep: true,
      immediate: true,

      handler(bol) {
        // 1. quickView组件的状态
        this.changeRootStatus({
          isSoldOut: bol
        })

        if (WEB_CLIENT != 'shein' && this.isFlashGoods) return
        // 是否有请求过abt信息
        // if (this.similarBtnAbt) {
        //   this.status.showSimilarBtn = this.similarBtnAbt.show ? bol : false
        // } else {
        bol && this.getSimilarEntry()
        // }
      }
    },
    activeId() {
      // 重置当前状态
      Object.assign(this.status, this.$options.data().status)
    },
    'addBagForm.quantity'() {
      this.changeStatus({
        clearance: false
      })
    },
    //同步当前销售属性库存提示状态
    'addBagForm.sizeInfo.stock'(val) {
      this.changeStatus({
        resultSoldout: val == 0
      })
    },
    'goodsData.detail.goods_sn': {
      handler(val) {
        if (val && !this.skuInfo?.sku_code) {
          this.getPoint()
          this.$nextTick(() => {
            this.triggerExposeBuyNow()
          })
        }
      },
      immediate: true
    },
    skuInfo: {
      handler(val) {
        if (val?.sku_code) {
          this.getPoint()
          this.$nextTick(() => {
            this.triggerExposeBuyNow()
          })
        }
      },
    }
  },
  mounted() {
    this.initPriceBannerNormalFlashCountDown()
    this.initTimer()
  },
  beforeUnmount() {
    this.priceBannerTypeNormalFlashData.flashTimer && this.priceBannerTypeNormalFlashData.flashTimer?.clear()
    this.priceBannerTypeNormalFlashData.secondInterval && clearInterval(this.priceBannerTypeNormalFlashData.secondInterval)
  },
  methods: {
    changeTips(stock) {
      return template(stock, this.language.SHEIN_KEY_PC_16400)
    },
    // 闪购倒计时
    async initPriceBannerNormalFlashCountDown() {
      if (this.isFlashSaleCountDownAbtHide) return
      if (this.IS_RW) return
      const { currTime: serverTime } = await schttp({ url: '/api/productInfo/currentTime/get' })
      if (this.flashGoods?.endTimestamp && this.flashGoods?.endTimestamp < serverTime ) return
      this.priceBannerTypeNormalFlashData.flashTimer = new CountDown({
        countFunc: (timeObj) => {
          const { D } = timeObj || {}
          // 倒计时小于72小时 = 3 天 时展示倒计时文案
          if (Number(D) < 1 && this.priceBannerTypeNormalFlashData.showCountTime === false) {
            this.priceBannerTypeNormalFlashData.showCountTime = true
            // 额外百毫秒展示
            // this.priceBannerTypeNormalFlashData.secondInterval = setInterval(() => {
            //   this.priceBannerTypeNormalFlashData.secondHundred --
            //   if (this.priceBannerTypeNormalFlashData.secondHundred < 0) this.priceBannerTypeNormalFlashData.secondHundred = 9
            // }, 100)
          }
          if (this.priceBannerTypeNormalFlashData.showCountTime) {
            Object.assign(this.priceBannerTypeNormalFlashData.countdownStr, timeObj)
          }
        },
        endFunc: () => {
          this.priceBannerTypeNormalFlashData.showCountTime = false
          this.priceBannerTypeNormalFlashData.showFlashBelt = false
          this.priceBannerTypeNormalFlashData.flashTimer && this.priceBannerTypeNormalFlashData.flashTimer?.clear()
          this.priceBannerTypeNormalFlashData.secondInterval && clearInterval(this.priceBannerTypeNormalFlashData.secondInterval)
        }
      })
      this.priceBannerTypeNormalFlashData.flashTimer.start({
        dayMode: true,
        seconds: this.flashGoods?.endTimestamp - serverTime, // 单位是秒
      })
    },
    /* 
      到手价倒计时 
    */
    initTimer() {
      const EstimatedTagProps = this.estimatedInfo?.estimatedCompProps
      const { isShowCountdown, bestEndTime } = EstimatedTagProps?.config || {}
      this.EstimatedTagProps = EstimatedTagProps
      this.isShowCountdown = isShowCountdown
      if (!isShowCountdown) return
      // 倒计时开始时间
      this.countTimer = new CountDown()
      const beginTime = bestEndTime * 1000 - new Date().getTime()
      if (beginTime <= 0) {
        beginTime == 0
      }

      // 初始化倒计时
      this.countTimer?.start({
        seconds: beginTime / 1000,
        endFunc: debounce({
          func: () => {
            // location.reload()
          }
        })
      })
    },
    /**
     * 唤起找相似结果页弹窗
     */
    handleShowSimilarModal() {
      const data = this.goodsData.detail
      if (!data) return

      SimilarPopover.showModal(data, { similarFrom: 'out_of_stock' })
      // 相似商品弹窗里面点击商品弹出quick_view, 为了防止多层重叠。打开相似商品弹窗时要关闭当前quick_view 弹窗
      this.$emit('handleClosePopups')

      const { goods_sn, goods_id } = data
      // 触发埋点上报
      daEventCenter.triggerNotice({
        daId: '1-6-1-3',
        extraData: {
          isOutOfStock: 1,
          sku: goods_sn,
          goods_id,
          activeFrom: this.quickViewConfig.styleType
            ? ''
            : this.quickViewConfig.activity_from || 'quick_view',
          similar_from: this.quickViewConfig.styleType ? 'lookbook_addbag' : ''
        }
      })
    },
    /**
     * 获取实验判断是否需要展示找相似入口
     */
    getSimilarEntry() {
      // let result = await getSimilarEntryAbtResult({ poskey: 'QuickaddbagSimilar' })
      // this.similarBtnAbt = result
      this.status.showSimilarBtn = true

      // 曝光相似商品的入口
      // if(result.show) {
      const { goods_sn, goods_id } = this.goodsData.detail
      daEventCenter.triggerNotice({
        daId: '1-6-1-2',
        extraData: {
          isOutOfStock: 1,
          sku: goods_sn,
          goods_id,
          activeFrom: this.quickViewConfig.styleType
            ? ''
            : this.quickViewConfig.activity_from || 'quick_view',
          similar_from: this.quickViewConfig.styleType ? 'lookbook_addbag' : '',
          abtest: this.analysis?.sa?.abtest || ''
        }
      })
      // }
    },
    // 销售属性入参
    getAttrParams(sizeInfo) {
      let attrsInfo = {}
      let skuCode = null
      let skcName = null
      if (this.hasAttrs && sizeInfo && Object.keys(sizeInfo).length) {
        skuCode = sizeInfo.sku_code
        skcName = sizeInfo.skc_name
        if (sizeInfo.sku_sale_attr?.length) {
          attrsInfo = sizeInfo.sku_sale_attr.reduce(
            (acc, cur) => {
              acc.attrs.push({
                attr_id: cur.attr_id,
                attr_value_id: cur.attr_value_id
              })
              if (cur.attr_id == 87) {
                acc.attrId = cur.attr_id || 0 // 尺码id
                acc.attrValueId = cur.attr_value_id || 0 // 尺码值id
                acc.attrValue = cur.attr_value_name || cur.attr_value // 尺码值（attr_value_name是新数据字段，后续等全部更新为新数据时，再统一取这个字段即可）
              }
              return acc
            },
            { attrs: [], attrId: '', attrValueId: '', attrValue: '' }
          )
        }
      }
      return { skuCode, skcName, ...attrsInfo }
    },
    /**
     * 购物车更新
     */
    handelClickQuickViewUpdate() {
      const {
        goods_id: goodsId,
        is_clearance,
        limit_count,
        goods_sn
      } = this.goodsData.detail
      const { sizeInfo = null, quantity = 1, id = '' } = this.addBagForm

      if (quantity == 0) return

      // 清仓限购
      if (is_clearance == 1 && quantity > limit_count) {
        this.changeStatus({
          clearance: true
        })
        return
      }

      // 1. 如果有销售属性，但是没有选择
      if (this.hasAttrs && !sizeInfo?.sku_code) {
        // 提示尺码没有选择
        this.changeRootStatus({
          notSelectSize: true
        })
        return
      }

      const traceid = gbExposeTraceid('getProduct', {
        goods_id: goodsId
      })

      // 加车的参数
      const formData = {
        id,
        goods_id: goodsId,
        quantity,
        mall_code: this.mallCode
      }

      if (traceid) formData.trace_id = traceid
      const { skuCode, attrs } = this.getAttrParams(sizeInfo)
      if (skuCode) formData.sku_code = skuCode
      if (attrs && attrs.length) formData.attrs = attrs
      if (window.cart_prime_product_code)
        formData.cart_prime_product_code = window.cart_prime_product_code || ''

      schttp({
        method: 'POST',
        url: '/api/cart/update_attr_mall/update',
        data: formData
      }).then(res => {
        if (this.quickViewCb) {
          const { handleUpdateDone = undefined } = this.quickViewCb
          this.$emit('handleClosePopups')
          daEventCenter.triggerNotice({
            daId: '1-7-1-39',
            extraData: {
              update_result: res.code == 0 ? 1 : 0,
              sku_id: goods_sn,
              mall_code: this.mallCode,
              ...(this.quickViewConfig?.showPromotionTagInfo
                ? {
                  promotion_type: this.activePromotionId || '-',
                  source: this.analysis?.source
                }
                : {})
            }
          })
          typeof handleUpdateDone == 'function' && handleUpdateDone(res)
        }
      })
    },
    /**
     * 自主换货点击确认
     */
    handleClickExchange() {
      if (this.exchangeInfo.showDisabledExchange) return 
      const { goods_id: goodsId } = this.goodsData.detail
      const {
        sizeInfo = null,
        billno = '',
        id = '',
        userName = '',
      } = this.addBagForm

      // 1. 如果有销售属性，但是没有选择
      if (this.hasAttrs && !sizeInfo?.sku_code) {
        // 提示尺码没有选择
        this.changeRootStatus({
          notSelectSize: true
        })
        return
      }
      if (this.status.addLoading) return

      const { exchangeSubmit = undefined } = this.quickViewCb || {}

      // 参数
      const formData = {
        billno: billno,
        user_name: userName,
        order_goods_id: id,
        replace_goods_id: goodsId
      }

      const { skuCode, attrId, attrValueId } = this.getAttrParams(sizeInfo)
      if (skuCode) formData.sku_code = skuCode
      if (attrId) formData.attr_id = attrId
      if (attrValueId) formData.attr_value_id = attrValueId
      if (Object.keys((this.quickViewConfig.customSubmitParam) || {}).length) {
        Object.assign(formData, this.quickViewConfig.customSubmitParam)
      }
      this.changeStatus({ addLoading: true })
      schttp({
        method: 'POST',
        url: '/api/orders/base/newExchangeGoods/create',
        timeout: 20000,
        data: formData
      })
        .then(res => {
          this.changeStatus({ addLoading: false })
          daEventCenter.triggerNotice({ daId: '2-8-9', extraData: { result: res.code == '0' ? 1 : 2 } })
          exchangeSubmit?.(res, { replace_goods_id: goodsId })
          if (res.code == '0') {
            setTimeout(() => {
              this.$emit('handleClosePopups')
            }, 2000)
          }
        })
        .catch((err) => {
          daEventCenter.triggerNotice({ daId: '2-8-9', extraData: { result: 2 } })
          this.changeStatus({ addLoading: false })
          exchangeSubmit?.(err, { replace_goods_id: goodsId })
        })
    },
    /**
     * 获取ga埋点信息
     */
    getGaReportInfo() {
      const { analysis = {} } = this
      const { ga } = analysis || {}
      const { moduleName, moduleTitle, gaList, eventCategory } = ga || {}
      return {
        // 默认空 如果是推荐位传入： recommend
        moduleName: moduleName,
        // 默认空 如果是推荐位传入：推荐位名称
        gaTitle: moduleTitle,
        //电商代码list 如果不传，值：PageGroup
        gaList: gaList,
        // 如果不传则会以当前页面名称作为ec的值
        eventCategory: eventCategory
      }
    },
    /**
     * 按钮埋点事件
     * @param btnType 'addCart' | 'ocp'
     * @param extraData
     */
    fireBtnAnalysis(btnType, extraData = {}) {
      const { goods_id: goodsId } = this.goodsData.detail
      const traceid = gbExposeTraceid('getProduct', {
        goods_id: goodsId
      })
      const { promotion_state, promotion_typeid } = this.quickViewConfig

      let threshold
      if (this.estimatedInfo?.value) {
        if (this.estimatedInfo?.isSatisfiedBuyMultiple) {
          threshold = 6
        } else if (this.isAb) {
          threshold = 3
        } else if (this.estimatedInfo?.isAbCoexist) {
          threshold = this.estimatedInfo?.optimalCoupon?.satisfied ? 4 : 5
        } else {
          threshold = this.estimatedInfo?.optimalCoupon?.satisfied ? 1 : 2
        }
      }
      const params = {
        daId: '1-7-1-1',
        target: (this.analysis && this.analysis.sourceTarget) || null,
        extraData: {
          button_type: btnType,
          code:
            (this.analysis && this.analysis.sa && this.analysis.sa.code) || '',
          from:
            this.analysis && this.analysis.sa && this.analysis.sa.activity_from,
          traceid: traceid,
          promotion_state,
          promotion_typeid,
          content_id: this.analysis?.sa?.content_id || '',
          extra_goods_list: this.analysis?.sa?.goods_list || '',
          poskey: this.analysis?.sa?.poskey || '',
          is_customize: this.isCustomizatie ? 1 : 0,
          coupon_type: this.analysis?.sa?.coupon_type || '', // 购物车页券筛选
          feed_type: this.analysis?.sa?.feed_type || '',
          ...extraData,
          ...(threshold ? { threshold } : {}) 
        }
      }
      daEventCenter.triggerNotice(params)
    },
    /**
     * 点击加车
     */
    async handleClickAddToBag(target, from) {
      const btnType = from || 'add_to_bag'
      const {
        goods_id: goodsId,
        goods_sn,
        is_clearance,
        limit_count
      } = this.goodsData.detail
      const {
        sizeInfo = null,
        quantity = 1,
        promotion_id,
        promotion_type = ''
      } = this.addBagForm

      const {
        isUpdateButton,
        birthdayGift,
        isExchangeButton,
        isShowSelectUpdate,
      } = this.quickViewConfig
      if (isUpdateButton) return this.handelClickQuickViewUpdate()
      if (isExchangeButton) return this.handleClickExchange()

      if (this.status.addLoading || this.status.showDone) return

      const traceid = gbExposeTraceid('getProduct', {
        goods_id: goodsId
      })

      if(this.isCustomizeNotMain){
        this.goDetail()
        return
      }

      // 1. 如果有销售属性，但是没有选择
      if (this.hasAttrs && !sizeInfo?.sku_code) {
        // 提示尺码没有选择
        this.changeRootStatus({
          notSelectSize: true
        })
        // 埋点
        this.fireBtnAnalysis(btnType, {
          result: { code: 1 },
          postData: {
            sku: goods_sn,
            goods_id: goodsId,
            mallCode: this.mallCode,
            is_default_mall: this.defaultMallCode == this.mallCode ? 1 : 0,
            quickShip: this.quickShip,
            location: 'popup',
            image_tp: 'small',
            ...(this.quickViewConfig.reportData || {})
          },
          faultReason: 'no select size'
        })
        return
      }
      if(isShowSelectUpdate) {
        return this.handleSelectUpdate()
      }
      // 生日礼点击下一步TODO:生日礼暂时关闭，后续开启再对入参进行更改
      if (birthdayGift && birthdayGift.hasAuthority4Gifts) {
        const { handleNextStepClick } = this.quickViewCb
        typeof handleNextStepClick == 'function' &&
          handleNextStepClick({
            goods_sn,
            goods_id: goodsId,
            attr_id: sizeInfo?.attr_id,
            attr_value_id: sizeInfo?.attr_value_id,
            attr_value: sizeInfo?.attr_value
          })
        return
      }

      // 加车的参数
      const formData = {
        // add_source: this.quickViewConfig?.addSource,
        goods_id: goodsId,
        quantity,
        mall_code: String(this.mallCode)
      }
      if (promotion_id) formData.promotion_id = String(promotion_id)
      if (promotion_type) formData.promotion_type = promotion_type
      if (traceid) formData.trace_id = traceid
      const { skuCode, attrs, attrValue } = this.getAttrParams(sizeInfo)
      if (skuCode) formData.sku_code = skuCode
      if (attrs && attrs.length) formData.attrs = attrs
      if (Object.keys((this.quickViewConfig.customSubmitParam) || {}).length) {
        Object.assign(formData, this.quickViewConfig.customSubmitParam)
      }
      if(this.isMainProduct){
        // 主商品唤起的快速加车弹窗
        const addSource = getQueryString({ key: 'add_source' }) || ''
        if(addSource) formData.add_source = `detail_${addSource}`
      }else {
        // 非主商品唤起的快速加车弹窗
        if(this.quickViewConfig?.add_source){
          formData.add_source = this.quickViewConfig.add_source
        }
      }
      if(this.isCustomizeMain){
        this.changeStatus({ addLoading: true })
        const sku_code = sizeInfo?.sku_code
        const { store_code } = this.goodsData.detail
        try {
          const result = await schttp({ 
            url: '/api/productInfo/customizationTspTag/query',
            params: { store_code: store_code }
          })
          const isCustomizationNewLink = result?.isCustomizationNewLink || false
          formData.customization_info = await customerMade({ sku: sku_code, isNewLink: isCustomizationNewLink })
        } catch (e) {
          SToast({
            content: this.language.SHEIN_KEY_PC_27739,
          }, 3000)
          this.changeStatus({ addLoading: false })
          return
        }
        this.changeRootStatus({
          popupModal: false
        })
      }

      // 清仓限购
      if (is_clearance == 1 && quantity > limit_count) {
        this.changeStatus({
          clearance: true
        })
        return
      }

      // 请求加车
      this.fetchAddToBag(formData, attrValue, btnType)
    },
    /**
     * 加车请求
     */
    fetchAddToBag(params, attrValue, btnType) {
      this.changeStatus({ addLoading: true })
      this.quickViewCb?.beginHandleAddToCart?.()
      const { goods_id, quantity, mall_code = '' } = params
      const {
        goods_sn,
        productRelationID,
        cat_id,
        mall = {},
        unit_discount,
        business_model,
        store_code
      } = this.goodsData.detail
      const salePrice = mall?.[this.mallCode]?.salePrice
      const url = `/api/cart/add_mall/create?goods_id=${goods_id}&quantity=${quantity}`

      monitor({ type: 5, params: this.monitorData })

      if (window.cart_prime_product_code)
        params.cart_prime_product_code = window.cart_prime_product_code || ''
      // params.abt_mark = this.goodsPicType
      schttp({
        method: 'POST',
        url,
        data: params
      })
        .then(res => {
          const { handleAddToCartDone } = this.quickViewCb || {}
          this.changeStatus({ addLoading: false })
          if (res.code == 0) {

            // 兼容旧接口反参结构
            res.cart = res.info.cart
            res.info = res.info.info
            // 按钮展示请求结果
            this.changeStatus({
              showDone: true,
              doneSuccess: true
            })
            setTimeout(() => {
              this.changeStatus({
                showDone: false
              })
              this.$nextTick(() => {
                // 关闭弹窗
                this.$emit('handleClosePopups')
              })
            }, 1000)

            if (!handleAddToCartDone) {
              if (!this.oneClickPayState?.upperBillNo) {
                // 快捷购物车刷新购物车清单
                window.cart_module
                  ? window.cart_module.recart(res)
                  : window.location.reload()
              }
            }
            this.changeRootStatus({ addBagStatus: 1 })
            monitor({ type: 3, params: this.monitorData })
          } else {
            // 按钮展示请求结果
            this.changeStatus({
              showDone: true,
              doneSuccess: false
            })
            setTimeout(() => {
              this.status.showDone = false
              this.changeStatus({
                showDone: false
              })
            }, 2000)
            const infoData = res.info || {}
            // 对不同状态吗判断
            if (res.code == 200401 || res.code == 500301) {
              this.changeStatus({
                purchaseLimit: true
              })
            } else if (res.code == 300402) {
              this.changeStatus({
                resultSoldout: true
              })
              this.updateOutStock()
            } else if (res.code == 300407) {
              // 购物车满了无法再继续加车
              this.changeRootStatus({
                rootTips: true,
                cartFullTips: true
              })
              setTimeout(() => {
                this.changeRootStatus({
                  rootTips: false,
                  cartFullTips: false
                })
              }, 2000)
            } else if (res.code == 300405) {
              this.changeStatus({
                clearanceLimit: true
              })
            } else if (['500306', '500302', '500303', '300417'].includes(res.code)) {
              const msgText = this.template(infoData?.limitCount || '', infoData?.resultLimit || 0, infoData?.remainNum || 0, res.tips)
              SMessage({
                message: msgText,
                type: 'error'
              })
            } else if (['300714', '302444'].includes(res.code)) {
              SMessage({
                message: this.template(
                  infoData?.limitCount || 0,
                  res.tips ||
                    this.htmlDecode({ text: this.language.SHEIN_KEY_PC_14560 })
                ),
                type: 'error'
              })
            } else {
              if (res.tips) {
                SMessage({
                  message: res.tips,
                  type: 'error'
                })
              }
            }
          }

          // 自定义回调，阻止默认埋点
          if (typeof handleAddToCartDone === 'function') {
            const bol = handleAddToCartDone(res, params, this.goodsData.detail)
            if (bol) return
          }

          // 只有非商详页这里才触发。商详页内部的加车后的自动领券需要更新券列表，得靠自己单独传入回调。 
          if (this.quickViewConfig?.addSource !== 'DetailPage' && this.quickViewConfig.showEstimatedPrice && this.estimatedInfo){ 
            const isSuccessed = res.code == 0
            autoGetCouponAtAddBagNotMain(this.estimatedInfo, this.language, isSuccessed)
          }
          
          let variant = ''
          if (attrValue) {
            variant = attrValue
          }

          // 埋点
          res.code == 0 && emarsys.checkCart()
          this.fireBtnAnalysis(btnType, {
            location: 'popup',
            image_tp: 'small',
            ...(this.getGaReportInfo() || {}),
            result: res,
            postData: Object.assign(
              {
                sku: goods_sn,
                spu: productRelationID || goods_sn,
                quickShip: this.quickShip,
                catId: cat_id,
                price: GB_ga_transformPrice(salePrice.amount),
                unit_discount,
                quantity,
                variant,
                amount: salePrice.amount,
                index: Number(this.analysis.index || 0),
                businessModel: business_model,
                storeCode: store_code,
                mallCode: mall_code,
                is_default_mall: this.defaultMallCode == mall_code ? 1 : 0,
                mallTag: this.goodsData.detail.mall[mall_code].mall_tags,
                ...(this.quickViewConfig.reportData || {})
              },
              params
            ),
            page_name:
              this.analysis && this.analysis.ga && this.analysis.page_name, // gtag
            detail: this.goodsData.detail,
            ...((this.analysis && this.analysis.extendAnalysis) || {})
          })
        })
        .catch(err => {
          this.changeStatus({
            addLoading: false,
            // 展示请求结果
            showDone: true,
            doneSuccess: false
          })
          setTimeout(() => {
            this.changeStatus({
              showDone: false
            })
          }, 2000)

          // 埋点
          this.fireBtnAnalysis(btnType, {
            location: 'popup',
            image_tp: 'small',
            result: { code: 1 },
            postData: Object.assign(
              {
                sku: goods_sn,
                quickShip: this.quickShip,
                is_default_mall:
                  this.defaultMallCode == params.mall_code ? 1 : 0,
                mallCode: mall_code,
                ...(this.quickViewConfig.reportData || {})
              },
              params
            ),
            faultReason: 'server_failure'
          })

          if (err?.status == 403) {
            //更新csrf_token
            schttp.updateXCsrfToken()
            // 上报日志
            SILogger.logInfo({
              tag: 'GOODS_DETAIL_PWA_CLIENT_V3',
              message: 'add_to_cart_error',
              params: {
                scene: 'add_to_cart',
                msgData: err
              }
            })
          }
        })
    },
    async clickBuyNow() {
      if (this.status.addLoading) return
      let { reason, isShow } = this.showBuyNowReason
      let quickship_tp = 0
      if (!!this.quickShip) {
        quickship_tp = this.goodsData?.screenAbtConfig?.DetailPageQuickShipShow?.param == 'DetailShowQuick' ? 1 : 0
      } else {
        quickship_tp = 3
      }
      const {
        sizeInfo = null,
        quantity = 1,
        promotion_id = '',
        promotion_type = ''
      } = this.addBagForm
      if (this.hasAttrs && !sizeInfo?.sku_code) {
        // 提示尺码没有选择
        this.changeRootStatus({
          notSelectSize: true
        })
        triggerSubmitBuyNow({
          goods_id: this.goodsData.detail.goods_id,
          mall_code: String(this.mallCode),
          quickship_tp,
          sku_code: '',
          sku_id: this.goodsData.detail.goods_sn,
          result: 0,
          result_reason: 'Please select size',
          location: 'popup',
          is_buynow: isShow ? 1 : 0,
          nobuynow_reason: reason
        })
        return
      }
      let checkout_total = this.estimatedInfo?.value?.amount || sizeInfo?.price?.salePrice?.amount
      this.changeStatus({ addLoading: true })
      this.quickViewCb?.beginHandleAddToCart?.()
      let data = await buyNow({
        language: this.language,
        httpSuccessCallBack: async () => await autoGetCouponAtAddBagNotMain(this.estimatedInfo, this.language, true),
        needJump: this.mainGoodsId == this.goodsData?.detail.goods_id,

        mall_code: String(this.mallCode),
        promotion_id: String(promotion_id),
        promotion_type,
        quantity,
        sku_code: sizeInfo?.sku_code,
        checkout_total,
        checkout_no: this.quickViewConfig.casualCheckoutNo,

        daData: {
          goods_id: this.goodsData.detail.goods_id,
          quickship_tp,
          sku_id: this.goodsData.detail.goods_sn,
          location: 'page',
          is_buynow: isShow ? 1 : 0,
          nobuynow_reason: reason
        }
      })
      this.changeStatus({ addLoading: false })
      if (data.code == 0) {

        this.changeStatus({
          showDone: true,
          doneSuccess: true
        })
        setTimeout(() => {
          this.changeStatus({
            showDone: false
          })
        }, 2000)
        this.$nextTick(() => {
          // 关闭弹窗
          this.$emit('handleClosePopups')
        })
      }
      if (typeof this.quickViewCb.handleAddToCartDone === 'function') {
        const bol = this.quickViewCb.handleAddToCartDone(data)
        if (bol) return
      }
    },
    triggerExposeBuyNow() {
      if (!this.isBuyNow) return
      let { reason, isShow } = this.showBuyNowReason
      setTimeout(() => {
        daEventCenter.triggerNotice({
          daId: '1-8-6-202',
          extraData: {
            activity_from: 'main',
            goods_id: this.goodsData.detail.goods_id,
            location: 'popup',
            is_buynow: isShow ? 1 : 0,
            nobuynow_reason: reason
          }
        })
      }, 200)
    },
    /**
     * 套装价弹窗点击size更新
     */
    handleSelectUpdate() {
      const { selectUpdateHook = undefined } = this.quickViewCb
      this.changeStatus({ addLoading: true })
      const { sizeInfo = null } = this.addBagForm
      const state = {
        attrs: this.getAttrParams(sizeInfo),
        item: this.goodsData.detail
      }
      const next = () => {
        this.changeStatus({ addLoading: false })
        this.changeRootStatus({
          popupModal: false
        })
      }
      typeof selectUpdateHook == 'function' && selectUpdateHook({ state, next })
    },
    /**
     * 没有库存，同步缓存数据
     */
    updateOutStock() {
      const { goods_id } = this.goodsData?.detail || {}
      // 这里为了更新对应属性的库存
      const skuNoStock = {}
      if (this.addBagForm?.sizeInfo?.sku_code) {
        if (skuNoStock[this.addBagForm.sizeInfo.sku_code]) {
          skuNoStock[this.addBagForm.sizeInfo.sku_code][this.mallCode] = true
        } else {
          skuNoStock[this.addBagForm.sizeInfo.sku_code] = {
            [this.mallCode]: true
          }
        }
      }

      this.changeRootData({
        goodsDataCache: {
          [goods_id]: void 0
        },
        skuNoStock
      })
      this.changeAddBagData({
        sizeInfo: {
          ...this.addBagForm.sizeInfo,
          stock: 0
        }
      })
    },
    /**
     * 点击收藏
     */
    handleClickAddWish() {
      if (this.status.addWishLoaidng) return
      const { goods_id } = this.goodsData.detail
      this.wishlistData[goods_id] == 1
        ? this.fetchCancleLike()
        : this.fetchAddLike()
    },
    /**
     * 收藏
     */
    fetchAddLike(addSuccessAfterLoginCb) {
      const { goods_id, goods_sn } = this.goodsData.detail
      const { sizeInfo = null } = this.addBagForm
      const { skuCode, skcName, attrValueId } = this.getAttrParams(sizeInfo)
      const params = { goods_id }
      if (skuCode) params.sku_code = skuCode
      if (skcName) params.skc_name = skcName
      if (attrValueId) params.attrValueId = attrValueId
      if (this.mallCode) params.mall_code = this.mallCode
      this.status.addWishLoaidng = true
      addWishGoodsApi(params).then(res => {
        this.status.addWishLoaidng = false
        const { handleAddWishDone, handleOpenLogin } = this.quickViewCb
        if (res.data && (res.data.code == 0 || res.data.code == 400401)) {
          // this.wishlistData[goods_id] = 1
          this.changeRootData({
            wishlistData: {
              ...this.wishlistData,
              [goods_id]: 1
            }
          })
          // 更新收藏
          this.updateWishList()
          // 检查是否加入分组
          this.checkShowAddGroup()

          if (typeof fastwish != 'undefined') {
            window.fastwish.reAddwish()
          }

          typeof handleAddWishDone == 'function' && handleAddWishDone(goods_id)
        } else if (res?.data?.code == 400427) {
          // 超出收藏限制
          SMessage({
            message: this.template(
              res.data.info.limit,
              this.language.SHEIN_KEY_PC_19347
            ),
            type: 'warning',
            offset: 164,
            duration: 3000
          })
          // this.$toast(this.template(res.data.info.limit, this.language.SHEIN_KEY_PC_19347), 3000)
        } else {
          if (res.code == -1 || (res.data && res.data.code == 300206)) {
            typeof handleOpenLogin == 'function' &&
              handleOpenLogin(this.fetchAddLike.bind(this, () => {
                const isWishList = location.pathname === '/user/wishlist'
                isWishList && location.reload()
                if (this.popupModal && typeof SHEIN_W !== 'undefined' && typeof window !== 'undefined' && !this.initLoginStatus && !!isLogin?.() && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD) {
                  window.appEventCenter = window.appEventCenter || mitt()
                  window.appEventCenter?.emit?.('detailLoginSucCb')
                }
              }))
            // typeof window.LOGIN_GA_POPUPS_SEND == 'function' && window.LOGIN_GA_POPUPS_SEND({ scenes: 'wishlist' })
          }
        }
        // 埋点】
        if (res.code != -1 && (res?.data?.code != 300206)) {
          daEventCenter.triggerNotice({
            daId: '1-7-1-2',
            target: (this.analysis && this.analysis.sourceTarget) || null,
            extraData: {
              location: 'popup',
              code:
                (this.analysis && this.analysis.sa && this.analysis.sa.code) ||
                '',
              result: res,
              mall_code: this.mallCode,
              eventCategory:
                this.analysis &&
                this.analysis.ga &&
                this.analysis.ga.eventCategory, // 如果不传则会以当前页面名称作为ec的值
              postData: {
                action: 1, // 选中发1, 取消发0
                goods_id,
                sku: goods_sn,
                quickShip: this.quickShip,
                detail: this.goodsData.detail,
                index: Number(this.analysis.index || 0),
              },
              feed_type: this.analysis?.sa?.feed_type || '',
              page_name:
                this.analysis && this.analysis.ga && this.analysis.page_name, //gtag
              poskey: this.analysis?.sa?.poskey || '',
              moduleName:
                this.analysis && this.analysis.ga && this.analysis.ga.moduleName, // 默认空 如果是推荐位传入： recommend
              from:
                this.analysis &&
                this.analysis.sa &&
                this.analysis.sa.activity_from
            }
          })
        }
        // 未登录的场景点击收藏登录以后再收藏成功后的回调
        if (res.data && (res.data.code == 0 || res.data.code == 400401)) {
          typeof addSuccessAfterLoginCb === 'function' && addSuccessAfterLoginCb()
        }
      })
    },
    /**
     * 取消收藏
     */
    fetchCancleLike() {
      const { goods_id, goods_sn } = this.goodsData.detail
      this.changeStatus({
        isShowAddGroup: false,
        addWishLoaidng: true
      })
      // todo: 引入文件 public/src/services/api/user/wishlist.js =>  wishlistDelApi
      schttp({ url: `/api/user/wishlist/del?goods_id=${goods_id}` }).then(res => {
        this.changeStatus({
          addWishLoaidng: false
        })

        if (res.data && res.data.info && res.data.info.result) {
          // this.wishlistData[goods_id] = 0
          this.changeRootData({
            wishlistData: {
              ...this.wishlistData,
              [goods_id]: 0
            }
          })
          // 更新收藏
          this.updateWishList()

          if (typeof fastwish != 'undefined') {
            window.fastwish.reAddwish()
          }

          // 取消收藏回调
          this.quickViewCb?.handleCancelWishDone?.(goods_id)
        }

        // 埋点】
        daEventCenter.triggerNotice({
          daId: '1-7-1-2',
          target: (this.analysis && this.analysis.sourceTarget) || null,
          extraData: {
            location: 'popup',
            code:
              (this.analysis && this.analysis.sa && this.analysis.sa.code) ||
              '',
            result: res,
            mall_code: this.mallCode,
            feed_type: this.analysis?.sa?.feed_type || '',
            eventCategory:
              this.analysis &&
              this.analysis.ga &&
              this.analysis.ga.eventCategory, // 如果不传则会以当前页面名称作为ec的值
            postData: {
              action: 0, // 选中发1, 取消发0
              goods_id,
              sku: goods_sn
            },
            from:
              this.analysis &&
              this.analysis.sa &&
              this.analysis.sa.activity_from
          }
        })
      })
    },
    /**
     * 点击去往详情
     */
    clickViewFullDetail() {
      const {
        detailFullLink = '',
        analysis = {},
        goodsData,
        quickShip,
        // commonAbtParams = {},
        oneClickPayState = {}
      } = this
      const { daIdConfig, ga, sa } = analysis
      // 触发埋点上报
      if (daIdConfig?.viewFullDetail) {
        daEventCenter.triggerNotice({
          daId: (daIdConfig && daIdConfig.viewFullDetail),
          extraData: {
            goodsData,
            abtest: sa?.abtest || '',
            gaList: ga && ga.gaList,
            eventCategory: (ga && ga.eventCategory) || '',
            activity_from: (sa && sa.activity_from) || '',
            style: sa?.style,
            feed_type: sa?.feed_type,
          }
        })
      }
      // 确保一次点击进入详情页的事件上报
      // const { UnderPrice } = commonAbtParams
      // let abtest = ''
      // if (UnderPrice) {
      //   const { child = [] } = UnderPrice
      //   const { bid = '', eid = '' } = child[0] || {}
      //   abtest = `UnderPrice\`${eid}\`${bid}`
      // }
      if (daIdConfig?.viewFullDetail !== '2-8-6') {
        const { upperBillNo } = oneClickPayState
        const saParams = {}
        if (sa?.viewDetailStyle) {
          saParams.style = sa.viewDetailStyle
        }
        daEventCenter.triggerNotice({
          daId: '2-8-6',
          extraData: {
            goodsData,
            quickShip,
            eventCategory: (ga && ga.eventCategory) || '',
            activity_from: upperBillNo
              ? 'one_tap_pay'
              : (sa && sa.activity_from) || '',
            feed_type: sa?.feed_type,
            look_type: sa?.look_type || '',
            look_name: sa?.look_name || '',
            ...(saParams || {})
            // abtest
            // style: 'detail',
          }
        })
      }

      if (detailFullLink) {
        setTimeout(async () => {
          await this.setDetailCdn()
          location.href = detailFullLink
          debounce({
            func: () => monitor({ type: 4, params: this.monitorData }),
            wait: 100
          })()
        }, 3e2)
      }
    },
    async setDetailCdn() {
      // todo: eddie 头部 header 临时处理 NEW_HEAD_SITE
      const { DETAIL_SSR_CDN_VERSION = '', DETAIL_SSR_CDN_SITE_STATUS, DETAIL_SSR_NO_CDN_SITE, NEW_HEAD_SITE } = this.goodsData.apolloConfig || {}
      const { CccGoodsdetail, goodsPicAb, PerformanceLogNodeTestFetch } = this.goodsData?.screenAbtConfig || {}
      const ABT_MAP = { CccGoodsdetail, goodsPicAb, PerformanceLogNodeTestFetch }

      await setDetailCacheCookie({
        DETAIL_SSR_CDN_VERSION, 
        DETAIL_SSR_CDN_SITE_STATUS, 
        DETAIL_SSR_NO_CDN_SITE,
        NEW_HEAD_SITE
      },
      ABT_MAP)
    },
    /**
     * 检查是否展示加入收藏分组
     */
    async checkShowAddGroup() {
      const { handleHookCheckAddToGroup } = this.quickViewCb

      const state = { show: false, groupList: [] }
      const next = (newState = {}) => {
        const _state = Object.assign({}, state, newState)
        if (!_state.show) return
        this.showWishGroup(!!_state.groupList.length)
      }
      const _doneFn = typeof handleHookCheckAddToGroup === 'function' ? () => handleHookCheckAddToGroup({ state, next }) : () => next()

      try {
        // eslint-disable-next-line
        const { AddShowGroup } = await abtservice.getUserAbtResult({
          posKeys: 'AddShowGroup'
        })
        const isShowAddGroup = AddShowGroup.param === 'ShowGroup'
        const res = await schttp({ url: '/api/user/wishlist/group/optional/goods/get' })
        const list = (res?.data?.info || {}).groupList || []
        state.show = isShowAddGroup || !!list.length
        state.groupList = list
      } catch(e) { /** empty */ }

      _doneFn()

    },
    /**
     * 展示收藏分组
     */
    showWishGroup(isLength) {
      this.changeStatus({ isShowAddGroup: true })
      daEventCenter.triggerNotice({
        daId: '1-4-1-8',
        extraData: { board_count: isLength ? 1 : 0 }
      })
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.changeStatus({ isShowAddGroup: false })
      }, 5000)
    },
    changeStatus(params) {
      for (const key in params) {
        this.status[key] = params[key]
      }
    },
    /**
     * 展示分组收藏
     */
    handleClickToCroup() {
      this.hide()

      // 黑暗料理：因为分组弹窗没有用组件库暂时用该方法应急解决
      const AllDialog = document.querySelectorAll('.new-goodsdc-dialog')
      let zIndex = 1
      if (AllDialog.length) {
        zIndex = AllDialog[AllDialog.length - 1].style.zIndex
      }

      const { activeId, quickViewCb } = this

      groupDialog().then(dialog => dialog.open(
        {
          zIndex,
          type: 4,
          goodsIds: [activeId],
          useFrom: '快速加车弹窗'
        },
        params => {
          this.updateWishList()
          const { handleAfterAddToBoard } = quickViewCb
          typeof handleAfterAddToBoard == 'function' &&
            handleAfterAddToBoard(params)
        }
      ))

      daEventCenter.triggerNotice({
        daId: '1-4-1-9'
      })
    },
    // 一键购按钮点击时的检测
    testBeforeOCP() {
      const {
        hasAttrs,
        addBagForm,
        changeRootStatus,
        goodsData,
        mallCode,
        defaultMallCode
      } = this
      const { goods_id, goods_sn } = goodsData?.detail || {}
      if (hasAttrs && !addBagForm?.sizeInfo?.sku_code) {
        // 提示尺码没有选择
        changeRootStatus({
          notSelectSize: true
        })
        // * HACK-oneClickPay 在此也会上报加车事件
        this.fireBtnAnalysis('one_tap_pay', {
          result: { code: 1 },
          postData: {
            sku: goods_sn,
            goods_id,
            mallCode: mallCode,
            is_default_mall: defaultMallCode == this.mallCode ? 1 : 0,
            quickShip: this.quickShip,
            location: 'popup',
            image_tp: 'small'
          },
          faultReason: 'no select size'
        })
        return false
      }
      return true
    },
    // 一键购成功，更新按钮状态
    onOneClickPaySuccess(successInfo) {
      this.$emit('updateOneClickPayStage')
      // * HACK-oneClickPay 在此也会上报加车事件
      const { fireBtnAnalysis, analysis = {}, goodsData } = this
      fireBtnAnalysis('one_tap_pay', {
        location: 'popup',
        image_tp: 'small',
        ...(this.getGaReportInfo() || {}),
        result: successInfo?.response || { code: 0 },
        postData: { ...(successInfo?.reqParams || {}) },
        // gtag
        page_name: (analysis?.ga && analysis?.page_name) || '',
        detail: goodsData.detail,
        ...(analysis?.extendAnalysis || {})
      })
    },
    // 一键购失败，更新按钮状态
    onOneClickPayFail(failInfo) {
      this.$emit('updateOneClickPayStage')
      // * HACK-oneClickPay 在此也会上报加车事件
      const { fireBtnAnalysis } = this
      fireBtnAnalysis('one_tap_pay', {
        location: 'popup',
        image_tp: 'small',
        result: { code: 1 },
        postData: { ...(failInfo?.reqParams || {}) },
        faultReason: 'server_failure'
      })
    },
    // 一键购支付会话和交互结束
    onOneClickPayTransComplete(type, extraData = {}) {
      const { result } = extraData
      const { quickViewCb, hide } = this
      // 成功，通知外部列表
      if (type === 'success') {
        quickViewCb?.handleOneClickPaySuccess?.(result)
      }
      // 失败，自动加车
      if (type === 'fail') {
        quickViewCb?.handleOneClickPayFail?.(result)
        const { handleClickAddToBag } = this
        handleClickAddToBag(null, 'one_tap_pay')
      }
      quickViewCb?.handleOneClickPayComplete?.({ type, extraData })
      setTimeout(() => {
        hide()
      }, 2e2)
    },
    template,
    htmlDecode,
    integralTips(rewardPoints) {
      const { template, language } = this
      let languageText = language.SHEIN_KEY_PC_22936
      // TODO 多语言暂时写死
      if (!!~SiteUID.indexOf('ar')) {
        if (IS_RW) {
          languageText = 'اكسب حتى {0} نقطة روموي عند دفع منتجاتك.'
        } else {
          languageText = languageText?.replace('XX', '{0}')
        }
      } else {
        languageText = languageText?.replace('XX', '{0}')?.replace('SHEIN', '{1}')
      }
      return template(
        `<span class="${IS_RW ? 'num' : ''}">${rewardPoints}</span>`,
        IS_RW ? 'ROMWE' : 'SHEIN',
        languageText
      )
    },
    goDetail() {
      const { daIdConfig, ga, sa } = this.analysis
      const {
        sizeInfo = null
      } = this.addBagForm
      const sku_code = sizeInfo?.sku_code
      // 触发埋点上报
      if (daIdConfig?.viewFullDetail) {
        daEventCenter.triggerNotice({
          daId: (daIdConfig && daIdConfig.viewFullDetail),
          extraData: {
            goodsData: this.goodsData,
            gaList: ga && ga.gaList,
            eventCategory: (ga && ga.eventCategory) || '',
            activity_from: (sa && sa.activity_from) || '',
          },
        })
      }
      let url = 'javascript:;'
      if (this.goodsData.detail) {
        const { goods_url_name, goods_id, cat_id } = this.goodsData.detail
        url = getGoodsUrl(goods_url_name, goods_id, cat_id) +
        '?imgRatio=' +
          this.fixedRatio
      }
      if(sku_code){ // 携带已选的sku
        url += '&skucode=' + sku_code
      }
      setTimeout(() => {
        window.location.href = url
      }, 0)
    },
    updateWishList() {
      // 收藏页的数据要更新
      if (window.gbProductWishlist) {
        typeof window.gbProductWishlist._updata == 'function' &&
              window.gbProductWishlist._updata()
      }
    },
    async getPoint() {
      await this.$nextTick()
      let { pointMap, skuInfo, goodsData, mallCode } = this || {}
      let sku = skuInfo?.sku_code || ''
      let skc = goodsData?.detail?.goods_id || ''
      if (!skc) return
      if (sku) {
        if (pointMap.sku[sku]) return
      } else {
        if (pointMap.skc[skc]) return
      }

      let currency_price = skuInfo?.mall?.[mallCode]?.salePrice?.amount || goodsData?.detail?.mall[mallCode]?.salePrice?.amount
      let mall_code = mallCode
      let product_first_category_id = goodsData?.parentCats?.cat_id
      let skc_business_model = goodsData?.detail?.business_model
      schttp({
        url: '/api/productInfo/point/productBudgetPointGive',
        params: {
          currency_price,
          mall_code,
          product_first_category_id,
          skc_business_model,
          skc,
          sku
        }
      }).then(res => {
        let point = res?.product_list?.[0]?.point_list?.[0]?.point
        if (sku) {
          pointMap.sku[sku] = point
        } else {
          pointMap.skc[skc] = point
        }
      })
    }
  },
  emits: ['handleClosePopups', 'updateOneClickPayStage']
}
</script>

<style lang="less">
.goods-btn__vip-birth-unable {
  width: 90%;
  min-height: 31px;
  padding: 6px 0;
  line-height: 1.5;
  background: #FCF0D4;
  margin-bottom: 16px;
  text-align: center;
}

.goods-btn__group {
  .button-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 51%;

    .product-intro__one-click-pay {
      width: 100%;
    }
    &__retention {
      right: 25px;
    }
    &__retention-onclick.only {
      right: 5px;
    }
  }

  .button-box.OCP-type {
    margin-right: 16px;

    .goods-btn__add,
    .product-intro__one-click-pay {
      width: calc(50% - 6px);
      font-size: 12px !important;
      flex: unset;
      margin-right: 0;
      padding: 0 16px;
    }
    .goods-btn__add {
      line-height: 56px;
      font-size: 15px;
      font-weight: 900;
      background-color: #fff;
      box-sizing: border-box;
      border: 1px solid @sui_color_gray_dark1;
      color: @sui_color_gray_dark1;
      transition: none;
    }
    .she-btn-xl__container {
      padding: 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .goods-btn__add-loading span {
      background-color: @sui_color_gray_dark1;
    }
  }
  // 利益点样式 todo: 影响到了loading
  // button.goods-btn__add {
  //     display: flex;
  //     white-space: nowrap;
  //     width: 100%;
  //     height: 56px;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     line-height: 26px;
  //     font-size: 15px;
  //     font-weight: 900;
  //     span {
  //       height: auto;
  //     }
  // }
}
.goods-btn__err {
  padding-bottom: 12px;
}
</style>
