<template>
  <div
    v-if="goodsData && goodsData.detail"
    class="goods-btn__container"
  >
    <!-- view full detail -->
    <a
      v-if="!quickViewConfig.hideViewFullDetail"
      class="view-detail-new"
      @click.stop="clickViewFullDetail"
    >
      {{ language.SHEIN_KEY_PC_14608 }}
      <span class="view-detail-new__more">
        <Icon
          name="sui_icon_more_right_18px"
          size="12px"
          class="view-detail-new__more-icon"
        />
      </span>
    </a>
    <!-- 积分收集提示 -->
    <!-- 挪入quickViewButton组件中一起吸底 -->
    <!-- <div class="goods-btn__collect">
      <p v-if="!quickViewConfig.birthdayGift">
        <template v-if="goodsDetail.doublePoints > 0">
          {{ language.SHEIN_KEY_PC_14616 }}&nbsp;
          <span>
            {{
              currnetPoint * addBagForm.quantity +
                goodsDetail.doublePoints * addBagForm.quantity
            }}
            <span
              style="color: #222"
            >({{ currnetPoint * addBagForm.quantity }} +</span>
            <span>{{ goodsDetail.doublePoints * addBagForm.quantity }}</span>
            <span style="color: #222">)</span>
          </span>
          &nbsp;{{ language.SHEIN_KEY_PC_14603 }}
        </template>
        <template v-else-if="currnetPoint * addBagForm.quantity > 0">
          <p
            v-html="
              integralTips(currnetPoint * addBagForm.quantity)
            "
          ></p>
        </template>
      </p>
    </div> -->
  </div>
</template>

<script>
import {
  debounce,
  template,
  htmlDecode,
} from '@shein/common-function'
// global variable: SHEIN_W gbExposeTraceid  cart_module  GB_ga_transformPrice
const { IS_RW, SiteUID } = gbCommonInfo
import { daEventCenter } from 'public/src/services/eventCenter'
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'
import { monitor } from '../quick_add_monitor'
import { getGoodsUrl, setDetailCacheCookie } from 'public/src/pages/common/utils/index.js'

export default {
  name: 'QuickViewIntegral',
  components: {
    // BtnContentWithPrice,
    Icon
  },
  props: {
    goodsData: {
      type: Object,
      required: true
    },
    quickViewConfig: {
      type: Object,
      default() {
        return {}
      }
    },
    // quickView 加车form
    addBagForm: {
      type: Object,
      required: true,
      default() {
        return {
          sizeInfo: null
        }
      }
    },
    analysis: {
      type: Object,
      default() {
        return {}
      }
    },
    pageListType: {
      type: String,
    },
    fixedRatio: {
      type: String,
      default: '3-4'
    },
    // 一键购状态
    oneClickPayState: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  inject: ['changeRootStatus', 'hide', 'changeRootData', 'changeAddBagData'],
  data() {
    return {
      pointMap: {
        sku: {},
        skc: {}
      }
    }
  },
  computed: {
    /**
     * 跳转到详情页的完整链接
     */
    detailFullLink() {
      if (this.goodsData.detail) {
        const { goods_url_name, goods_id, cat_id } = this.goodsData.detail
        const { detailUrlExtendParam } = this.quickViewConfig
        let extendUrl = ''
        if (detailUrlExtendParam) {
          Object.keys(detailUrlExtendParam).forEach((item) => {
            extendUrl += `&${item}=${detailUrlExtendParam[item]}`
          })
        }
        if (this.oneClickPayState?.upperBillNo) {
          extendUrl += `&billno=${this.oneClickPayState?.upperBillNo}`
        }
        if (this.pageListType !== undefined) {
          extendUrl += `&pageListType=${this.pageListType || ''}`
        }
        if(this.quickViewConfig.add_source){
          // 新增加车归因参数add_source
          extendUrl += `&add_source=${this.quickViewConfig.add_source}`
        }
        return (
          getGoodsUrl(goods_url_name, goods_id, cat_id) +
          '?mallCode=' +
          this.mallCode +
          '&imgRatio=' +
          this.fixedRatio + 
          extendUrl
        )
      }
      return ''
    },
    language() {
      return this.$root.language || {}
    },
    goodsDetail() {
      return (this.goodsData && this.goodsData.detail) || {}
    },
    skuInfo() {
      const { addBagForm } = this
      return addBagForm?.sizeInfo
    },
    mallCode() {
      return this.goodsData.mallCode
    },
    currnetPoint() {
      let skc = this.goodsData?.detail?.goods_id || ''
      let sku = this.skuInfo?.sku_code || ''
      let { pointMap } = this
      if (sku && pointMap.sku[sku]) return pointMap.sku[sku]
      if (skc && pointMap.skc[skc]) return pointMap.skc[skc]
      return 0
    },
    quickShip() {
      return this.goodsData.quickShip
    },
  },
  watch: {
    'goodsData.detail.goods_sn': {
      handler(val) {
        if (val && !this.skuInfo?.sku_code) {
          this.getPoint()
        }
      },
      immediate: true
    },
    skuInfo: {
      handler(val) {
        if (val?.sku_code) {
          this.getPoint()
        }
      },
    }
  },
  methods: {
    template,
    htmlDecode,
    integralTips(rewardPoints) {
      const { template, language } = this
      let languageText = language.SHEIN_KEY_PC_22936
      // TODO 多语言暂时写死
      if (!!~SiteUID.indexOf('ar')) {
        if (IS_RW) {
          languageText = 'اكسب حتى {0} نقطة روموي عند دفع منتجاتك.'
        } else {
          languageText = languageText?.replace('XX', '{0}')
        }
      } else {
        languageText = languageText?.replace('XX', '{0}')?.replace('SHEIN', '{1}')
      }
      return template(
        `<span class="${IS_RW ? 'num' : ''}">${rewardPoints}</span>`,
        IS_RW ? 'ROMWE' : 'SHEIN',
        languageText
      )
    },
    /**
     * 点击去往详情
     */
    clickViewFullDetail() {
      const {
        detailFullLink = '',
        analysis = {},
        goodsData,
        quickShip,
        // commonAbtParams = {},
        oneClickPayState = {}
      } = this
      const { daIdConfig, ga, sa } = analysis
      // 触发埋点上报
      if (daIdConfig?.viewFullDetail) {
        daEventCenter.triggerNotice({
          daId: (daIdConfig && daIdConfig.viewFullDetail),
          extraData: {
            goodsData,
            abtest: sa?.abtest || '',
            gaList: ga && ga.gaList,
            eventCategory: (ga && ga.eventCategory) || '',
            activity_from: (sa && sa.activity_from) || '',
            style: sa?.style,
            feed_type: sa?.feed_type,
          }
        })
      }
      // 确保一次点击进入详情页的事件上报
      // const { UnderPrice } = commonAbtParams
      // let abtest = ''
      // if (UnderPrice) {
      //   const { child = [] } = UnderPrice
      //   const { bid = '', eid = '' } = child[0] || {}
      //   abtest = `UnderPrice\`${eid}\`${bid}`
      // }
      if (daIdConfig?.viewFullDetail !== '2-8-6') {
        const { upperBillNo } = oneClickPayState
        const saParams = {}
        if (sa?.viewDetailStyle) {
          saParams.style = sa.viewDetailStyle
        }
        daEventCenter.triggerNotice({
          daId: '2-8-6',
          extraData: {
            goodsData,
            quickShip,
            eventCategory: (ga && ga.eventCategory) || '',
            activity_from: upperBillNo
              ? 'one_tap_pay'
              : (sa && sa.activity_from) || '',
            feed_type: sa?.feed_type,
            look_type: sa?.look_type || '',
            look_name: sa?.look_name || '',
            ...(saParams || {})
            // abtest
            // style: 'detail',
          }
        })
      }

      if (detailFullLink) {
        setTimeout(async () => {
          await this.setDetailCdn()
          location.href = detailFullLink
          debounce({
            func: () => monitor({ type: 4, params: this.monitorData }),
            wait: 100
          })()
        }, 3e2)
      }
    },
    async setDetailCdn() {
      // todo: eddie 头部 header 临时处理 NEW_HEAD_SITE
      const { DETAIL_SSR_CDN_VERSION = '', DETAIL_SSR_CDN_SITE_STATUS, DETAIL_SSR_NO_CDN_SITE, NEW_HEAD_SITE } = this.goodsData.apolloConfig || {}
      const { CccGoodsdetail, goodsPicAb, PerformanceLogNodeTestFetch } = this.goodsData?.screenAbtConfig || {}
      const ABT_MAP = { CccGoodsdetail, goodsPicAb, PerformanceLogNodeTestFetch }

      await setDetailCacheCookie({
        DETAIL_SSR_CDN_VERSION, 
        DETAIL_SSR_CDN_SITE_STATUS, 
        DETAIL_SSR_NO_CDN_SITE,
        NEW_HEAD_SITE
      },
      ABT_MAP)
    },
    async getPoint() {
      await this.$nextTick()
      let { pointMap, skuInfo, goodsData, mallCode } = this || {}
      let sku = skuInfo?.sku_code || ''
      let skc = goodsData?.detail?.goods_id || ''
      if (!skc) return
      if (sku) {
        if (pointMap.sku[sku]) return
      } else {
        if (pointMap.skc[skc]) return
      }

      let currency_price = skuInfo?.mall?.[mallCode]?.salePrice?.amount || goodsData?.detail?.mall[mallCode]?.salePrice?.amount
      let mall_code = mallCode
      let product_first_category_id = goodsData?.parentCats?.cat_id
      let skc_business_model = goodsData?.detail?.business_model
      schttp({
        url: '/api/productInfo/point/productBudgetPointGive',
        params: {
          currency_price,
          mall_code,
          product_first_category_id,
          skc_business_model,
          skc,
          sku
        }
      }).then(res => {
        let point = res?.product_list?.[0]?.point_list?.[0]?.point
        if (sku) {
          pointMap.sku[sku] = point
        } else {
          pointMap.skc[skc] = point
        }
      })
    }
  },
  emits: ['handleClosePopups', 'updateOneClickPayStage']
}
</script>

<style lang="less">
.goods-btn__vip-birth-unable {
  width: 90%;
  min-height: 31px;
  padding: 6px 0;
  line-height: 1.5;
  background: #FCF0D4;
  margin-bottom: 16px;
  text-align: center;
}

.goods-btn__group {
  .button-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 51%;

    .product-intro__one-click-pay {
      width: 100%;
    }
    &__retention {
      right: 25px;
    }
    &__retention-onclick.only {
      right: 5px;
    }
  }

  .button-box.OCP-type {
    margin-right: 16px;

    .goods-btn__add,
    .product-intro__one-click-pay {
      width: calc(50% - 6px);
      font-size: 12px !important;
      flex: unset;
      margin-right: 0;
      padding: 0 16px;
    }
    .goods-btn__add {
      line-height: 56px;
      font-size: 15px;
      font-weight: 900;
      background-color: #fff;
      box-sizing: border-box;
      border: 1px solid @sui_color_gray_dark1;
      color: @sui_color_gray_dark1;
      transition: none;
    }
    .she-btn-xl__container {
      padding: 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .goods-btn__add-loading span {
      background-color: @sui_color_gray_dark1;
    }
  }
  // 利益点样式 todo: 影响到了loading
  // button.goods-btn__add {
  //     display: flex;
  //     white-space: nowrap;
  //     width: 100%;
  //     height: 56px;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     line-height: 26px;
  //     font-size: 15px;
  //     font-weight: 900;
  //     span {
  //       height: auto;
  //     }
  // }
}
</style>
